.icons ul li {
  display: inline-block;
  color: #fff;
  border-right: 1px solid #fff;
  transition: all 0.2s ease 0s;
}

.icons ul li:hover {
  box-shadow: #626262 0px 0px 8px 0px;

  color: #000000;
  cursor: pointer;
}

.icons ul li:last-child {
  border-right: none;
}

.icons ul {
  border: 1px solid #fff;
  padding: initial;
  border-radius: 10px;
}

.middle-content {
  color: #fff;
}

.base-info {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 5px 0px 5px 0px;
}

.base-info h4 {
  font-size: 23px;
  font-weight: 200;
}

.base-info p {
  font-size: 13px;
  font-weight: 200;
  margin-top: 10px;
  color: #fff;
  margin-bottom: 10px;
  line-height: 22px;
}

.member-price-main {
  text-align: center;
}

.member-price {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 3px;
}

.item {
  width: 130px;
}

.item.members {
  display: inline-block;
  border-right: 1px solid #000;
}

.item.prices {
  display: inline-block;
}

.item p {
  font-size: 14px;
  font-weight: normal;
  margin: 2px;
  line-height: 21px;
}

/* .login-btn {
  color: #000000 !important;
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  border: none;
  padding: 7px 18px;
  text-align: center;
  border-radius: 3px;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: none;
  font-family: Arial, Helvetica, sans-serif;
  max-height: 37px;
}

.login-btn:hover {
  color: #ffffff !important;
  -ms-opacity: 0.95 !important;
  opacity: 0.95 !important;
} */

.icons {
  float: right;
}

.icons ul li img {
  width: 50px;
  margin: 0px;
  padding: 12px;
}
.middle-content-wrapper .col-md-6 {
  padding: 0;
}
.middle-content-wrapper .row {
  margin: 0;
}
.middle-content-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
}
.item.prices strong img {
  display: inline-block;
  width: 8px;
  height: 12px;
  overflow: hidden;
}

@media only screen and (max-width: 820px) {
  .middle-content-wrapper button {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .middle-content-wrapper {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .icons ul li img {
    width: 50px;
    padding: 10px;
  }

  .icons {
    margin-top: 20px;
  }

  .middle-content-wrapper {
    margin-top: 25px;
    align-items: flex-end;
    flex-direction: column-reverse;
    /* padding-right: 5px; */
  }
}
