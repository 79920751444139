.register-form {
  background: #fafafa;
  margin: auto;
  height: 100%;
}

.register-form h3 {
  font-size: 19px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

form.register-form-wrapper {
  overflow-x: hidden;
}

/* .tbtc-col-3,
.email-name-surname-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 15px 0px;
}

.tbtc-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 15px 0px;
}

.email-name-surname-wrapper input {
  width: 100%;
  color: #2c2c2c;
}

.register-form-wrapper input:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
}

.mobile-index-public-admin-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin: 15px 0px;
}
.five-column-wrapper {
  grid-template-columns: repeat(5, 1fr);
}
.four-column-wrapper {
  grid-template-columns: repeat(4, 1fr);
}
.three-coloumn-wrapper {
  grid-template-columns: repeat(3, 1fr);
}
.two-column-wrapper {
  grid-template-columns: repeat(2, 1fr);
}

.mobile-index-public-admin-wrapper .whs-index input,
.mobile-index-public-admin-wrapper .mobile input,
.token-id input,
.locker-number input,
.tbtc-input input {
  width: 100%;
  color: #2c2c2c;
}

.wallet-adress-golfer-img-picker-wrapper {
  display: grid;
  grid-template-columns: 50% 15% 35%;
  grid-gap: 10px;
  margin: 15px 0px;
}

.wallet-address input {
  width: 100%;
  color: #2c2c2c;
}

.bio-irl-membership-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 15px 0px;
}

.irl-membership textarea,
.bio textarea {
  width: 100%;
  height: 200px;
  padding: 20px 10px 7px 10px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  color: #2c2c2c;
}

.irl-membership textarea:focus,
.bio textarea:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
}

.irl-membership textarea::placeholder,
.bio textarea::placeholder {
  color: #c2c2c2;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.create-confirm-password-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 15px 0px;
}

.create-password input,
.confirm-password input {
  width: 100%;
  color: #2c2c2c;
}

.img-picker input {
  width: 100%;
} */

/* .register-form-wrapper input
{
  padding: 20px 10px 7px 10px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
}

.register-form-wrapper input::placeholder {
  color: #c2c2c2;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.is-public span,
.is-super-admin span,
.is-golfer span{
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: normal;
  color: #626262;
}

.is-public,
.is-super-admin,
.is-golfer
{
  text-align: center;
}

.img-picker span {
  margin-left: 10px;
}

.is-public input,
.is-super-admin input,
.is-golfer input
{
  background-color: yellow;
}

.send-email-form-submission {
  display: flex;
  align-items: center;
  justify-content: end;
}

.welcome-email {
  margin-right: 10px;
}

.welcome-email input {
  margin-right: 10px;
}

.register-form-button input {
  background: #e6b812 !important;
  border: none;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s;
  font-family: Arial, Helvetica, sans-serif;
}

.register-form-button input:hover {
  color: #fff;
}

.register-form-wrapper {
  width: 100%;
  margin: auto;
  padding: 10px 140px;
}

.email,
.first-name,
.surname,
.mobile,
.whs-index,
.wallet-address,
.create-password,
.confirm-password,
.token-id,
.locker-number,
.tbtc-input {
  position: relative;
}

.img-picker {
  position: relative;
}

.wallet-address input {
  width: 90%;
}

.email span,
.first-name span,
.surname span,
.mobile span,
.whs-index span,
.wallet-address span,
.create-password span,
.confirm-password span,
.token-id span,
.locker-number span,
.tbtc-input span {
  position: absolute;
  left: 11px;
  color: #626262;
  font-size: 14px;
  top: 7px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
}

.img-picker span {
  position: absolute;
  left: 40px;
  color: #626262;
  font-size: 14px;
  top: 2px;
  font-weight: 500;
  font-size: 12.5px;
  font-family: "Montserrat", sans-serif;
  width: 210px;
}

.img-picker input {
  padding: 28px 10px 10px 10px;
}

.img-picker i {
  position: absolute;
  position: absolute;
  top: 20px;
  right: 10px;
  background: #e6b812;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}

.bio,
.irl-membership {
  position: relative;
}

.bio span,
.irl-membership span {
  position: absolute;
  left: 11px;
  color: #626262;
  font-size: 14px;
  top: 7px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
}

.wallet-address i {
  padding: 5px;
  border-radius: 50%;
  color: #e6b812;
  font-size: 25px;
}

.profile-pic-svg {
  background: #e6b812;
  padding: 10px;
  border-radius: 50%;
}

.welcome-email span {
  font-size: 13px;
  color: #626262;
}

.input--file {
  position: relative;
  color: #7f7f7f;
  display: inline-block;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.image-item {
  text-align: right;
}

.locker-progress {
  display: grid;
  grid-template-columns: 5% 25% 70%;
  background: #f2f2f2;
  margin: 5px 0px;
  align-items: center;
  justify-content: space-between;
}

.locker-progress-item {
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
}

.checkbox-delete-area {
  display: flex;
  margin: 0px 10px 0px 10px;
  align-items: center;
  gap: 10px;
}

.progress-item .progress {
  width: 100%;
  height: 20px;
  border-radius: inherit !important;
}

.is-default-area span {
  margin-left: 5px;
  font-size: 14px;
}

.locker-number-item {
  margin: 10px 15px;
}

.locker-number-item {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.number-inner span {
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #626262;
  letter-spacing: 1px;
}

.number-inner h5,
.progress-item span {
  font-size: 14px;
  color: #595959;
  font-weight: 400;
  text-transform: capitalize;
}

.required-star {
  color: red;
  position: absolute;
  top: 5px;
  right: 10px;
}

.remove-btn {
  background: #fff;
  padding: 10px 7px;
  border-radius: 2px;
}

.remove-btn i {
  color: #626262;
  cursor: pointer;
}

.progress-bar {
  background: #e6b812;
} */

/* input[type="checkbox"] {
  accent-color: #e6b812;
  color: #fff;
} */

/* .number-inner {
  position: relative;
  width: 100%;
}

.number-inner span {
  position: absolute;
  left: 10px;
  top: 5px;
}

.number-inner span {
  position: absolute;
  left: 10px;
  top: 5px;
}

.number-inner input {
  width: 100%;
  border: none;
}

.invalid-field {
  border: 1px solid red !important;
}

.locker-number-item input::placeholder {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}

.phone-number-label {
  left: 50px !important;
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .register-form-wrapper {
    padding: 20px 80px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    display: grid;
    grid-template-columns: 5% 25% 70%;
    background: #f2f2f2;
    margin: 5px 0px;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1440px) {
  .register-form-wrapper {
    padding: 20px 80px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    display: grid;
    grid-template-columns: 5% 25% 70%;
    background: #f2f2f2;
    margin: 5px 0px;
    align-items: center;
    justify-content: space-between;
  }

  .locker-progress-item {
    display: grid;
    grid-template-columns: 80% 20%;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .register-form-wrapper {
    padding: 20px 80px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    grid-template-columns: 7% 28% 65%;
  }

  .locker-progress-item {
    display: grid;
    grid-template-columns: 65% 35%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .register-form-wrapper {
    padding: 20px 80px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    grid-template-columns: 10% 35% 55%;
  }

  .locker-progress-item {
    display: grid;
    grid-template-columns: 60% 40%;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 601px) and (max-width: 768px) {
  .img-picker span {
    width: 100px;
  }

  .wallet-address i {
    position: absolute;
    top: 8px;
    right: -10px;
  }

  .register-form-wrapper {
    padding: 20px 20px;
  }

  .wallet-adress-golfer-img-picker-wrapper {
    grid-template-columns: 50% 25% 25%;
  }

  .is-golfer {
    text-align: center;
  }

  .locker-progress-item {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .bio-irl-membership-wrapper {
    margin: 30px 0px 0px 0px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    grid-template-columns: 10% 40% 50%;
  }

  .progress-item {
    display: none;
  }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 486) and (max-width: 600px) {
  .register-form-wrapper {
    padding: 10px;
  }

  .tbtc-col-3,
  .tbtc-col-2,
  .email-name-surname-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .mobile-index-public-admin-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .is-public,
  .is-super-admin,
  .is-golfer{
    text-align: left;
  }

  .wallet-adress-golfer-img-picker-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .bio-irl-membership-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .irl-membership textarea,
  .bio textarea {
    height: 100px;
  }

  .create-confirm-password-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .user-details {
    margin-left: 50px;
  }

  .image-item {
    text-align: right;
  }

  .locker-progress {
    display: grid;
    grid-template-columns: 10% 35% 55%;
  }

  .progress-item {
    display: none;
  }
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (min-width: 280px) and (max-width: 767px) {
  .img-picker span {
    width: 250px;
  }

  .register-form-wrapper {
    padding: 10px;
  }

  .tbtc-col-3,
  .tbtc-col-2,
  .email-name-surname-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .mobile-index-public-admin-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .is-public,
  .is-super-admin,
  .is-golfer{
    text-align: left;
  }

  .wallet-adress-golfer-img-picker-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .bio-irl-membership-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .wallet-address i {
    position: absolute;
    top: 8px;
    right: 0px;
  }

  .irl-membership textarea,
  .bio textarea {
    height: 100px;
  }

  .create-confirm-password-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .user-details {
    margin-left: 50px;
  }

  .img-picker {
    margin: 15px 0px;
  }

  .image-item {
    text-align: right;
  }

  .progress-item {
    display: none;
  }

  .locker-progress {
    grid-template-columns: 15% 45% 40%;
  }
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (min-width: 280px) and (max-width: 375px) {
  .locker-progress {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .image-item {
    text-align: left;
    margin: 10px 0px 0px 10px;
  }

  .remove-btn {
    margin-bottom: 10px;
  }
} */
