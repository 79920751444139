.login-input {
  position: relative;
}
/* .login-btn {
  position: relative !important;
} */
.spinner-border {
  left: 0 !important;
  top: 10px !important;
}
.invalid-input-field {
  border: 1px solid red !important;
}
.login-input span {
  position: absolute;
  left: 10px;
  color: #ffffff;
  font-size: 14px;
  top: 17px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
}
.confirmation-message {
  color: white;
}
.login-input input {
  width: 100%;
  height: 55px;
  background: transparent;
  padding: 7px 12px 4px 10px;
  border: 1px solid #626262;
  border-radius: 2px;
  margin: 5px 0px;
  color: #9b9b9b;
  padding-top: 25px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.resend-confirmation-code-link-wrapper {
  text-align: right;
}
.login-input input:focus {
  outline: none;
  background: transparent !important;
}

.login-input input:focus ~ span {
  color: #9b9b9b !important;
}
.login-wrapper .bg-pic {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.login-wrapper .bg-pic::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #00000054;
}

.login-wrapper .bg-pic > img {
  height: 100%;
  position: absolute;
}

.login-wrapper > * {
  height: 100%;
}

.login-wrapper .login-container {
  background: #202225;
  width: 510px;
  display: block;
  position: relative;
  float: right;
  height: 100vh;
  padding-top: 80px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 20px;
}

.policy-area p {
  color: #fff;
  font-size: 12px;
  text-align: justify;
  line-height: 21px;
}

.login-policy-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

img.login-form-image {
  margin-bottom: 10px;
}

.resend-confirmation-code-link {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%;
  }

  .login-wrapper .login-container {
    padding-top: 80px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%;
  }

  .login-wrapper .login-container {
    padding-top: 80px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 40px;
  }
}
