.gif-img {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
}

header {
	position: relative;
}

.gif-img img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-bottom: -50px;

}


@media only screen and (max-width: 600px) {

	.gif-img img {
		width: 50px;
		height: 50px;

	}

	.gif-img {
		bottom: 15px;

	}
}