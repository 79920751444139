/* * {
    box-sizing: border-box;
} */

#progress {
  padding: 0;
  list-style-type: none;
  font-family: arial;
  font-size: 12px;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
}

#progress li {
  float: left;
  padding: 10px 30px 10px 40px;
  background: #ebebeb;
  color: #626262;
  position: relative;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  width: 32%;
  margin: 0 1px;
}

#progress li:before {
  content: "";
  border-left: 16px solid #fff;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}
#progress li:after {
  content: "";
  border-left: 16px solid #ebebeb;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
  color: #2c2c2c;
  background: #f6d355;
  border-top-color: #f6d355;
  border-bottom-color: #f6d355;
}

#progress li.active:after {
  border-left-color: #f6d355;
}

#progress li.completed {
  color: #2c2c2c;
  background: #e6b812;
  border-top-color: #e6b812;
  border-bottom-color: #e6b812;
}

#progress li.completed:after {
  border-left-color: #e6b812;
}

.goat-circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  font-size: 18px;
  color: #626262;
  line-height: 100px;
  text-align: center;
  background: #ebebeb;
}
.success-goat-circle {
  color: #2c2c2c;
  background: green;
}
.failed-goat-circle {
  color: #2c2c2c;
  background: red;
}
