.search {
  display: inline-block;
  position: relative;
}

.search i {
  position: absolute;
  right: 8px;
  top: 20px;
  color: #474747;
  cursor: pointer;
}

.search input {
  padding: 5px 30px 5px 10px;
  margin: 10px 0px;
  background: transparent;
  border: 1px solid #626262;
  border-radius: 2px;
  color: #fff;
  outline: none;
}

@media only screen and (max-width: 600px) {
  .search {
    margin-top: 15px;
  }
}