.login-input input {
  height: 55px !important;
}
.login-wrapper .bg-pic {
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.tbtc-sdk-btn {
  position: relative !important;
}
.spinner-border {
  left: 0 !important;
  top: 10px !important;
}

.login-wrapper .bg-pic::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #00000054;
}

.login-wrapper .bg-pic > img {
  height: 100%;
  position: absolute;
}

.login-wrapper > * {
  height: 100%;
}

.login-wrapper .login-container {
  background: #202225;
  width: 510px;
  display: block;
  position: relative;
  float: right;
  height: 100vh;
  padding-top: 80px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 20px;
}

.policy-area p {
  color: #fff;
  font-size: 12px;
  text-align: justify;
  line-height: 21px;
}

.login-policy-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

img.login-form-image {
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%;
  }

  .login-wrapper .login-container {
    padding-top: 80px;
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%;
  }

  .login-wrapper .login-container {
    padding-top: 80px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 40px;
  }
}
