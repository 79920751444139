.attribut-remove-btn {
  margin-left: 10px !important;
  color: red;
  font-size: 20px;
  cursor: pointer;
}
.attribut-remove-btn:hover {
  opacity: 0.7;
}
.attribute-group {
  align-items: center;
}

.attribute-group input:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
  border-color: #ebebeb !important;
  box-shadow: none !important;
}
.contract-address-search {
  top: 28px !important;
  right: 9px !important;
}
.contract-address-input {
  padding-right: 29px !important;
}
