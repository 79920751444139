.email-wallet-address {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding-left: 10px;
}
/* .wallet-address input {
  width: 70%;
} */

.resent-activation-code-link {
  color: #e6b812;
  font-size: 12px;
  text-decoration: none;
}
.resent-activation-code-link:hover {
  color: #e6b812;
}
.wallet-address {
  display: flex;
  align-items: center;
}
.wallet-control-btn {
  width: 85px !important;
}
.wallet-disable-btn {
  background: #d61f2c;
  color: #ffffff;
}
.goat-phone-number {
  margin: 10px 0 0 0;
}
@media only screen and (max-width: 767px) {
  .wallet-control-btn {
    width: 45px !important;
  }
}
