.gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.main-gallery {
  width: 1440px;
  margin: auto;
}

.image-wrapper-main,
.image-wrapper-main img {
  height: 516px;
  width: 516px;
  background-size: cover;
  display: block;
  overflow: hidden;
}

.single-gallary-item img {
  width: 100%;
  transition: 0.4s ease-in-out;
}

.single-gallary-item {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 240px;
  width: 280px;
}

.modal-open-area {
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
}

.info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 8px 20px;
  margin-bottom: -50px;
  transition: margin-bottom 0.3s ease-in-out;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 75%
  );
  width: 100%;
}
.single-gallary-item:hover .info {
  margin-bottom: 0;
}
.user-edit-btn {
  cursor: pointer;
}
.info .user-info-wrapper {
  color: #fff;
  font-size: 12px;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.info .user-info-wrapper img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.info .user-info-wrapper span {
  margin-left: 10px;
}

.modal-img-info {
  padding-left: 25px;
  padding-top: 35px;
  padding-right: 35px;
}

.modal-img-info h4,
h6 {
  color: #2c2c2c;
  font-size: 18px;
  font-weight: normal;
}

.modal-img-info h3 {
  font-size: 22px;
  color: #2c2c2c;
  font-weight: normal;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.golf-courses-items {
  max-height: 250px;
  overflow-y: auto;
}

.modal-img-info h4,
h6 {
  font-family: "Montserrat", sans-serif;
  color: #2c2c2c;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10;
}

.golf-courses-items span {
  display: block;
  font-size: 13px;
  color: #626262;
}

.modal-img-info h5 {
  color: #626262;
  font-size: 14px;
  font-weight: normal;
}

.left-info-modal img {
  width: 48px;
  height: 48px;
  clip-path: circle(50%);
}

.modal-item-adress {
  text-align: right;
}

.left-info-modal h4,
.modal-item-adress h4 {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #b9b9b9;
}

.btn:focus {
  outline: none !important;
  border: none !important;
}

.left-info-modal-leftarea h5,
.modal-item-adress h5 {
  font-size: 13px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 5px;
}

.left-info-modal-leftarea {
  margin-left: 15px;
}

.left-info-modal {
  display: flex;
  align-items: center;
}

.modal-img-info p {
  font-size: 13px;
  color: #626262;
  height: 80px;
  overflow: auto;
  margin-bottom: 10px;
}

.info h5 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 28px;
}

.gallary {
  margin: 20px;
}

.goatDetailsModal .modal-dialog {
  max-width: 840px;
}

.goatDetailsModal .modal-content {
  border-radius: 0px !important;
  border: none !important;
}

/* .modal-item-footer {
    padding: 10px 15px;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

/* .modal-item-footer {
    padding: 12px 15px;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.goatDetailsModal .modal-item-footer {
  padding: 10px 15px;
  background: #000;
  color: #fff;
  display: grid;
  grid-template-columns: 20% 60% 20%;
}

.goatDetailsModal .modal-footer-images {
  text-align: end;
}

.goatDetailsModal .modal.fade {
  opacity: 1 !important;
  background: rgba(55, 58, 71, 0.9) !important;
  padding: 0 !important;
}

.goatDetailsModal .modal-item-footer .modal-footer-images img {
  width: 56px;
  height: 56px;
  margin: 0px 0px 0px 15px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 20px;
  background: none;
  border: none;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  font-size: 12px;
  cursor: pointer !important;
}

.close-button i {
  color: #000;
  text-shadow: 0 1px 0 #fff;
  font-size: 12px;
}

.thumbnail-wrapper {
  overflow: hidden;
  min-width: 492px;
}

.goatDetailsModal .modal.show .modal-dialog {
  margin: 0 auto !important;
}

.goatDetailsModal .modal.fade .modal-dialog {
  margin: 0 auto !important;
}

/*mobile-modal-info*/
.goatDetailsModal .modal-item-footer-mobile {
  background: #000;
  display: flex;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.goatDetailsModal .left-info-modal-mobile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.goatDetailsModal .left-info-modal-mobile {
  display: flex;
  align-items: center;
  width: 50%;
}

.goatDetailsModal .modal-item-address-mobile h5 {
  font-size: 13px;
  color: #fff;
  margin: 0;
  line-height: 1;
  font-weight: 300;
}

.goatDetailsModal .modal-item-address-mobile h4 {
  font-size: 13px;
  color: #626262;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 1;
  margin-top: 10px;
  color: #bbbbbb;
  font-weight: normal;
}

.goatDetailsModal .modal-item-address-mobile {
  width: 50%;
  text-align: end;
}

.goatDetailsModal .left-info-modal-mobile h4 {
  color: #bbb;
  font-size: 13px;
  font-weight: 300;
}

.user-info-wrapper {
  display: flex;
  align-items: center;
}
.name-edit-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/*mobile-modal-info*/

@media only screen and (max-width: 1440px) {
  .main-gallery {
    width: 1150px;
    margin: auto;
  }

  .gallery-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .main-gallery {
    width: 860px;
    margin: auto;
  }

  .gallery-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Medium devices (landscape tablets, 820px and up) */
@media only screen and (max-width: 820px) {
  .gallery-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-gallery {
    width: 570px;
    margin: auto;
  }

  .thumbnail-wrapper {
    overflow: hidden;
    min-width: 440px;
  }

  .goatDetailsModal .modal-img-info {
    padding-left: 15px;
    padding-top: 35px;
    padding-right: 35px;
  }
}

/* Medium devices (landscape tablets, 820px and up) */
@media only screen and (max-width: 768px) {
  .thumbnail-wrapper {
    overflow: hidden;
    min-width: 440px;
  }

  .goatDetailsModal .modal-img-info {
    padding-left: 15px;
    padding-top: 35px;
    padding-right: 35px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
  .image-wrapper-main,
  .image-wrapper-main img {
    height: 516px;
    width: 100%;
    background-size: cover;
    display: block;
    overflow: hidden;
  }

  .main-gallery {
    width: 100% !important;
    margin: auto;
  }

  .gallery-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .gallary {
    margin: 0px;
  }

  .single-gallary-item {
    height: 300px;
    margin: 0px;
    overflow: hidden;
    width: 100%;
  }

  .middle-content-wrapper {
    justify-content: center;
    align-items: center;
  }

  .goatDetailsModal .modal-item-adress {
    text-align: left;
    margin: 15px 0px;
  }

  .goatDetailsModal .modal-img-info h6 {
    margin-top: 20px;
  }

  .goatDetailsModal .modal-item-footer {
    display: none;
  }

  .image-wrapper-main,
  .image-wrapper-main img {
    width: 100%;
  }

  .goatDetailsModal .modal-img-info {
    padding-bottom: 35px;
  }
  .search {
    margin-left: 10px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 280px) and (max-width: 485px) {
  .middle-content-wrapper {
    flex-direction: column;
  }

  .goatDetailsModal .modal-item-footer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
  }

  .goatDetailsModal .modal-item-adress {
    text-align: left;
    margin: 15px 0px;
  }

  .goatDetailsModal .modal-img-info h6 {
    margin-top: 20px;
  }

  .goatDetailsModal .modal-item-footer {
    display: none;
  }

  .goatDetailsModal .modal-img-info {
    padding: 35px;
  }
}
