.logo img {
  width: 45px;
}

.goat-gallery-header {
  background: #202225;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 120px;
}

.user-details img {
  width: 30px;
  border-radius: 50%;
}

.user-details {
  display: flex;
  align-items: center;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.user-details::after {
  border: none !important;
  margin: 0 !important;
}

.user-details h5 {
  color: #595959;
  font-size: 13px;
  margin: 0;
  margin-right: 15px;
}
.tbtc-menu-link.active,
.tbtc-menu-link:active,
.tbtc-menu-link:hover {
  color: #1e2125;
  text-decoration: none;
  background-color: #e9ecef;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 486) and (max-width: 600px) {
  .goat-gallery-header {
    justify-content: space-between;
    padding: 5px 10px;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 280px) and (max-width: 485px) {
  .goat-gallery-header {
    justify-content: space-between;
    padding: 5px 10px;
  }
}
