.phone-number {
  position: relative;
}
/* .phone-number .form-control {
  border: 1px solid #ebebeb !important;
  border-radius: 0 !important;
  height: 55px !important;
  width: 100% !important;
} */
.phone-number .form-control {
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  background: transparent;
  /* padding: 7px 12px 4px 10px; */
  border: 1px solid #626262;
  border-radius: 2px;
  margin: 5px 0px;
  color: #9b9ba7;
  padding-top: 25px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.phone-number .country-list .search {
  width: 100%;
}
.phone-number .flag-dropdown {
  background-color: transparent;
  /* border: 1px solid #ebebeb !important; */
  border: 1px solid #626262;
}
/* .phone-number .react-tel-input .country-list .search {
  padding: 0 10px 0 10px;
} */
.react-tel-input .country-list .search-box {
  color: black;
  width: 90%;
}
.phone-number label {
  position: absolute;
  left: 50px;
  /* color: #626262; */
  color: white;
  font-size: 14px;
  top: 7px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
}

.phone-number .form-control:focus {
  box-shadow: none;
  background: none;
}
.phone-number .react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: none;
}
.phone-number .react-tel-input .flag-dropdown.open {
  background-color: transparent;
}
.phone-number .react-tel-input .flag-dropdown.open .selected-flag {
  background: none;
}
