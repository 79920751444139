/* ::-webkit-scrollbar {
  display: none;
} */
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.goat-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}
.PhoneInputCountry,
.PhoneInputCountrySelectArrow {
  color: #e6b812 !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.padding-0 {
  padding: 0 !important;
}
.margin-0 {
  margin: 0 !important;
}
@media (max-width: 480px) {
  .m-sm-t-10 {
    margin-top: 10px !important;
  }
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-15 {
  font-size: 15px !important;
}

.invalid-field {
  border: 1px solid red !important;
}

.tbtc-form-group {
  width: 100%;
  position: relative;
  margin: 10px 0 0 0;
}
.tbtc-form-group input {
  padding: 20px 10px 7px 10px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  width: 100%;
  color: #2c2c2c;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.tbtc-form-group select {
  padding: 20px 10px 7px 10px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  width: 100%;
  color: #2c2c2c;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.tbtc-form-group label {
  position: absolute;
  left: 11px;
  color: #626262;
  font-size: 14px;
  top: 7px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 10.5px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
.tbtc-form-group input:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
  border-color: #ebebeb;
  box-shadow: none !important;
}
.tbtc-form-group select:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
  border-color: #ebebeb;
  box-shadow: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}
.tbtc-form-group input:disabled {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
  border-color: #ebebeb !important;
  box-shadow: none !important;
  /* color: #626262; */
  color: #2c2c2c;
  -webkit-text-fill-color: #2c2c2c;
  opacity: 1; /* required on iOS */
}
.tbtc-input {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  width: 100%;
  color: #2c2c2c;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}
.tbtc-input:focus {
  background: rgba(0, 0, 0, 0.03);
  outline: none !important;
  border-color: #ebebeb;
  box-shadow: none !important;
}
.tbtc-form-group .required-star {
  color: red;
  position: absolute;
  top: 5px;
  right: 10px;
}
.tbtc-form-group .search-icon {
  position: absolute;
  right: 5px;
  top: 16px;
  color: #626262;
  font-size: 20px;
  cursor: pointer;
}
.tbtc-search-group input {
  padding-right: 25px;
}

.tbtc-checkbox {
  text-align: left;
  text-align: center;
  display: flex;
  align-items: center;
}
.tbtc-checkbox input {
  background-color: #e6b812;
  padding: 20px 10px 7px 10px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  accent-color: #e6b812;
  color: #ffffff;
}
.tbtc-checkbox label {
  text-align: left;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: normal;
  color: #626262;
}
.tbtc-radio {
  text-align: left;
  text-align: center;
  display: flex;
  align-items: center;
}
.tbtc-radio input:checked {
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  box-shadow: none !important;
}
.tbtc-radio input:focus-visible,
.tbtc-radio input:active {
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  outline-color: #e6b812 !important;
  box-shadow: none !important;
}
.tbtc-radio label {
  text-align: left;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  font-weight: normal;
  color: #626262;
}

.tbtc-form-group textarea {
  width: 100%;
  height: 200px;
  padding: 0 10px 0 10px;
  border: none;
  border-top: 25px solid white;
  /* border: 1px solid #ebebeb; */
  box-shadow: 0 0 0 1px #ebebeb;
  border-radius: 2px;
  color: #2c2c2c;
}

.tbtc-form-group textarea:focus {
  background: rgba(0, 0, 0, 0.03);
  border-top: 25px solid rgba(0, 0, 0, 0);
  outline: none !important;
}

.tbtc-form-group textarea::placeholder {
  color: #c2c2c2;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.tbtc-form-wrapper .header-text {
  font-size: 19px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.tbtc-form-wrapper {
  width: 100%;
  margin: auto;
  padding: 10px 140px;
}

.tbtc-form .col-md-1,
.tbtc-form .col-md-2,
.tbtc-form .col-md-3,
.tbtc-form .col-md-4,
.tbtc-form .col-md-5,
.tbtc-form .col-md-6,
.tbtc-form .col-md-7,
.tbtc-form .col-md-8,
.tbtc-form .col-md-9,
.tbtc-form .col-md-10,
.tbtc-form .col-md-11,
.tbtc-form .col-md-12,
.tbtc-form .col-sm-12 {
  /* padding: 0; */
  padding-left: 5px;
  padding-right: 5px;
}
.tbtc-form .row {
  margin: 0;
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .tbtc-form-wrapper {
    padding: 20px 80px;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1440px) {
  .tbtc-form-wrapper {
    padding: 20px 80px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
  .tbtc-form-wrapper {
    padding: 20px 20px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .tbtc-form-wrapper {
    padding: 20px 20px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .tbtc-form-wrapper {
    padding: 20px 20px;
  }
  .img-picker span {
    width: 100px;
  }
}
@media only screen and (min-width: 486) and (max-width: 600px) {
  .tbtc-form-wrapper {
    padding: 10px;
  }
  .tbtc-checkbox {
    text-align: left;
  }
}
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .tbtc-form-wrapper {
    padding: 10px;
  }
  .img-picker span {
    width: 250px;
  }
  .img-picker {
    margin: 15px 0px;
  }
  .tbtc-checkbox {
    text-align: left;
  }
}
.tbtc-btn {
  color: #000000 !important;
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  border: none;
  padding: 7px 18px;
  text-align: center;
  border-radius: 3px;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: none;
  font-family: Arial, Helvetica, sans-serif;
  max-height: 37px;
  font-weight: 100 !important;
}

.tbtc-btn:hover {
  color: #ffffff !important;
  -ms-opacity: 0.95 !important;
  opacity: 0.95 !important;
}
.danger-btn {
  background-color: red !important;
}
@media only screen and (max-width: 767px) {
  .tbtc-btn {
    padding: 5px 5px;
    font-size: 11px;
  }
}

.input--file {
  position: relative;
  color: #7f7f7f;
  display: inline-block;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.img-picker {
  position: relative;
}

.img-picker span {
  margin-left: 10px;
  position: absolute;
  left: 40px;
  color: #626262;
  font-size: 14px;
  top: 2px;
  font-weight: 500;
  font-size: 12.5px;
  font-family: "Montserrat", sans-serif;
  width: 210px;
}

.img-picker input {
  padding: 28px 10px 10px 10px;
  width: 100%;
}

.img-picker i {
  position: absolute;
  position: absolute;
  top: 20px;
  right: 10px;
  background: #e6b812;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}
.goat-phone-number .form-control {
  border: 1px solid #ebebeb !important;
  border-radius: 0 !important;
  height: 55px !important;
  width: 100% !important;
  color: #2c2c2e !important;
  background-color: white !important;
}
.goat-phone-number .form-control:focus {
  background: rgba(0, 0, 0, 0.03) !important;
}
.goat-phone-number .flag-dropdown {
  border: 1px solid #ebebeb !important;
}
.goat-phone-number label {
  color: #626262 !important;
}
.register-form-wrapper .goat-phone-number {
  margin: 0;
}
.register-form-wrapper .goat-phone-number {
  margin-top: 10px;
}
.tbtc-modal .modal-header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 0;
  border: none;
  font-size: 12px;
}
.tbtc-modal .modal-title {
  font-size: 16px;
}
.tbtc-modal .modal-body {
  padding-top: 0;
}
.tbtc-modal .modal-header .btn-close:focus {
  box-shadow: none;
}
.tbtc-modal .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
}

.copy-button {
  position: absolute;
  right: 5px;
  top: 4px;
  color: #626262;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 10.5px;
  cursor: pointer;
  padding: 2px 4px;
}
.copy-button:hover {
  color: #ffffff !important;
  -ms-opacity: 0.95 !important;
  opacity: 0.95 !important;
  background-color: #e6b812 !important;
  border-color: #e6b812 !important;
  border: none;
  padding: 3px 6px;
  text-align: center;
  border-radius: 3px;
  transition: 0.3s;
  letter-spacing: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

/* Mobile device only */
@media only screen and (max-width: 767px) {
  .fs-sm-12 {
    font-size: 12px !important;
  }
}
